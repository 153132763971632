import React from 'react';
import { Link } from 'react-router-dom';
import AppContext from './../helpers/AppContext'
import SizeList from './../helpers/SizeList'
import Scroller from './../helpers/Scroller'
import { Container, Row, Col, Accordion, Card, Button, Tabs, Tab, InputGroup,FormControl, Modal   } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';


export default class Social extends React.Component 
{
    static contextType = AppContext;

    constructor(props) 
    {
        super(props);
        
    }
    
    componentDidMount()
    {
        const params={
            language:this.props.match.params.language,
            structure:1,
            level:20,
            resetPage:true,
        };
       
        const { setData } = this.context;
        setData(params);
        
    }
   
    
    render() 
    {
        const { structure, loading } = this.context;
        
        
        if (Object.keys(structure).length !=0 )
        {
            return (
              <div >
                    <div className='main social' id='social'>
                        <div className="main-sep"></div>
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <div className="titolo">
                                        <div dangerouslySetInnerHTML={{__html: structure.social.titolo}}></div>
                                    </div>
                                </Col>
                            </Row>    
                            <Row>    
                                <Col  >
                                    <div className='lista-social'>
                
                                {
                                    structure.social.lista.map((item, i) => 
                                    {     
                                        return (
                                                 
                                                        <a href={item.link.url} target={item.link.target} key={'lista-social-'+i}>
                                                            { item.immagine && (
                                                            <img src={item.immagine} className='icona' />)
                                                            }
                                                        </a>
                                               
                                        )

                                    })
                                }
                                    </div>
                                 </Col>
                            </Row>
                        </Container>
                        <div className='m-f'></div>
                    </div>
                            
                            
                    
                   
                    <Scroller id='social'/>
              </div>
                      
            );
        }else{
            return (
                    
                <div>
                    <div className='main technical-data'>
                        <div className="main-sep"></div>
                        <Container>
                            <Row>
                                <Col>
                                    <div className="titolo">
                                        <Skeleton />
                                    </div>

                                    
                                    <div className="result">
                                        <Skeleton height={100} />
                                        
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
              </div>
                    
                    
                    
                    );
            
        }
            
    }
    
}

