import React from 'react';
import AppContext from './../helpers/AppContext';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Accordion, Card, Button } from 'react-bootstrap';
import Select from 'react-select'

export default class SizeFilter extends React.Component 
{
    static contextType = AppContext;
    constructor(props) 
    {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        
        this.onFocus=this.onFocus.bind(this)
        this.onBlur=this.onBlur.bind(this)
        this.reset_size=this.reset_size.bind(this)
        
        let filters={};
        

        
        this.props.select_lista.map((voce, i) => {
            filters[voce.variabile]={titolo: voce.titolo, placeholder: voce.placeholder, attivo:0, options:[]};
        }); 
        this.state={filters:filters}
    }
    reset_size() 
    {
       this.update_filter('init');
    }
  
    onFocus(e)
    {
        var _this=this;
        setTimeout(function(){
        if(document.getElementsByClassName('main'))
        {
            var posy=e.target.getBoundingClientRect().y + document.getElementsByClassName('main')[0].scrollTop-150;    
//            document.getElementsByClassName('main')[0].scroll(0, posy)
            scrollTo(document.getElementsByClassName('main')[0], posy,400);
        }
    },300);

     //   e.target.closest(".filter").classList.add('active');
    }
    onBlur(e)
    {
        window.scroll(0,0)
       // e.target.closest(".filter").classList.remove('active');
    }
    componentDidMount()
    {
        var _this=this;
        const { structure, size_list } = this.context;
        this.update_filter('init');
    }
    get_options(var_m, new_filters)
    {
        let size_list_param
        const { size_list } = this.context;
        if (this.props.lista_param && this.props.lista_param.length)
        {
            size_list_param=this.props.lista_param;
        }else{
           size_list_param=size_list
        }
        let _this=this;
        let output;
        // converto i dati del size_list_param per renderli utili alla select
        let output_raw= size_list_param.map(function (item) {
            return ({label:item[var_m], value:item[var_m], key:var_m, item: item});
        });
        let key_lista=Object.keys(new_filters);
        let key='';
        let filters=[];
        for (var i = 0; i < key_lista.length; i++) 
        {
            key=key_lista[i]
            if(new_filters[key].attivo && new_filters[key].value)
            {
                filters.push({key:key, value:new_filters[key].value})
            }
        }
        // elimino i doppioni
        var prev = {};
        output_raw = output_raw.filter( function(arr) {
            
            for (var i = 0; i < filters.length; i++) 
            {
                if (arr['item'][filters[i].key] != filters[i].value)
                {
                    return false;
                }
            }

            var key = arr['label'];
            if (prev[key]){ return false;}
            return (prev[key] = true);
        });
        
        output=output_raw;
       
        if (var_m=='width' || var_m=='Rim_Size' )
        {
            output.sort((a, b) => {
                return  b.label-a.label;
            });
        }else{
            if ( var_m=='load')
            {
                output.sort((a, b) => {
                    return  a.label-b.label;
                });
            }else{
                output.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
                
            }
            
        }
       
        return output;
    }
    update_filter(var_m, value)
    {
      
        var _this=this;
        var ultimo_field_compilato_impostato=false;
        var primo_field_da_compilare_impostato=false;
        
        var new_filters={};
        let filters_key=Object.keys(this.state.filters);
        filters_key.unshift('init');
       
        if (var_m =='init' && this.props.lastTSCOpen)
        {
            
            Object.keys(this.state.filters).map((voce, i) => {
                _this.state.filters[voce].attivo=1;
                _this.state.filters[voce].value=this.props.lastTSCOpen[voce]
            });
            var_m='last';
            
        }
        for (var i = 0; i < filters_key.length; i++) 
        {
            var key=filters_key[i];
            
            if (!ultimo_field_compilato_impostato)
            {
                if (var_m==key )
                {
                    if (_this.state.filters[key])
                    {
                        // ultima select selezionata
                        new_filters[key]=
                        {   
                            titolo: _this.state.filters[key].titolo,
                            placeholder: _this.state.filters[key].placeholder,
                            attivo:1, 
                            options:_this.get_options(key,new_filters), 
                            value:value
                        };
                    }
                    ultimo_field_compilato_impostato=true;
                }else{
                    if (_this.state.filters[key])
                    {
                        // select già impostate precedentemente
                        new_filters[key]=
                        {
                            titolo: _this.state.filters[key].titolo,
                            placeholder: _this.state.filters[key].placeholder,
                            attivo:1,  
                            options:_this.get_options(key,new_filters), 
                            value:_this.state.filters[key].value
                        };
                    }
                }
            }else{
                if (_this.state.filters[key])
                {
                    if (!primo_field_da_compilare_impostato)
                    {
                        // prossima select da selezionare
                        new_filters[key]=
                        {
                            titolo: _this.state.filters[key].titolo, 
                            placeholder: _this.state.filters[key].placeholder,
                            attivo:1, 
                            options:_this.get_options(key,new_filters), 
                            value:undefined
                        };
                        primo_field_da_compilare_impostato=true
                    }else{
                        // select disattivate
                        new_filters[key]=
                        {
                            titolo: _this.state.filters[key].titolo, 
                            placeholder: _this.state.filters[key].placeholder,
                            attivo:0, 
                            options:[], 
                            value:' '
                        };

                    }
                }
            }
        }
        this.setState({filters:new_filters});
      
        let completed=true;
        filters_key=Object.keys(new_filters);
        filters_key.map((key, i) => 
        {

            if (new_filters[key].attivo && new_filters[key].value)
            {

            }else{
                
                completed=false
            }
        })
        
        if(completed)
        {
            this.props.onCompleted(_this.get_options('last',new_filters)[0].item)
        }else{
            this.props.onUncompleted()

        }
    }
    
    
    handleChange(data) 
    {
     
     /*
        document.querySelectorAll(".filter.active").forEach(function(i,v)
        {
            i.classList.remove('active');
        })
             * 
      */
        document.activeElement.blur();

        const { structure } = this.context;
        let filters_key=Object.keys(this.state.filters);
        for (var i = 0; i < filters_key.length; i++) 
        {
            var key=filters_key[i];
            
            if (data.key==key)
            {
                this.update_filter(key, data.value);
                break;
            }
        }
     
        
    }
    
    render() {
       
        const { structure, size_list } = this.context;
        const { filters } = this.state;
        let select_value;
        let _this=this
        const customStyles = {
            menuList: (provided, state) => ({
                ...provided,
                maxHeight:'195px',
                //paddingBottom: '120px',
            }),
            control: (provided, state) => ({
                ...provided,
                borderRadius : '0 !important; ',
            }),
            option: (provided, state) => ({
                ...provided,
                border : '1px solid #eeeeee !important; '
            }),
            menu: (provided, state) => ({
                ...provided,
                opacity : '1 !important; ',
                zIndex : '9999; ',
                position : 'relative; '
            }),
        }
        
        if (size_list.length !=0 && Object.keys(this.state.filters).length !=0 )
        {
                return (
                    <div className={this.state.selectFocus ? 'size-filter select-focus ' :'size-filter '}>
                            {
                                Object.keys(filters).map((key, i) => 
                                {     
                                    select_value=null;
                                    if (filters[key].value )
                                    {
                                        select_value={label:filters[key].value}
                                    }

                                    return (
                                        <div className="filter"  key={'sizefilterselect'+key+i}>
                                            <Row className="align-items-top " >
                                                <Col xs={12} sm={3} lg={2} dangerouslySetInnerHTML={{__html: filters[key].titolo}} className="filter-titolo"></Col>
                                                <Col xs={12} sm={9}  lg={10} className="filter-container-select" ><Select  className="select2-container" styles={customStyles} placeholder={filters[key].placeholder} options={filters[key].options} value={ select_value } onFocus={this.onFocus} onBlur={this.onBlur} onChange={this.handleChange} isDisabled={!filters[key].attivo} /></Col>
                                            </Row>
                                        </div>
                                        
                                    ) 
                                })
                            }
                            
                    </div>
                );
            
            
            
        }else{
            return (
                ''
            );
        }
    }
    
}

function scrollTo(element, to, duration) {
    var start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20;
        
    var animateScroll = function(){        
        currentTime += increment;
        var val = Math.easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;
        if(currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };
    animateScroll();
}

//t = current time
//b = start value
//c = change in value
//d = duration
Math.easeInOutQuad = function (t, b, c, d) {
  t /= d/2;
	if (t < 1) return c/2*t*t + b;
	t--;
	return -c/2 * (t*(t-2) - 1) + b;
};
