import React from 'react';
import axios from 'axios';
import { BrowserRouter, Route, Switch, Link, HashRouter, useLocation,useHistory,Redirect   } from 'react-router-dom';


import { TransitionGroup ,CSSTransition } from 'react-transition-group'
import { AppProvider  } from './helpers/AppContext'

import AppContext from './helpers/AppContext'

import Topbar from './helpers/Topbar';
import Home from './pages/Home';
import Languages from './pages/Languages';
import ProductRange from './pages/ProductRange';
import Product from './pages/Product';
import Size from './pages/Size';
import TechnicalData from './pages/TechnicalData';
import TechnicalDataSymbols from './pages/TechnicalDataSymbols';
import TechnicalInformations from './pages/TechnicalInformations';
import TechnicalInformationsPage from './pages/TechnicalInformationsPage';
import Contacts from './pages/Contacts';
import Social from './pages/Social';
import Apps from './pages/Apps';
import ProductSearch from './pages/ProductSearch';
import MechanicalLeadCalculator from './pages/MechanicalLeadCalculator';
import TireSizeCorrispondence from './pages/TireSizeCorrispondence';
import TireSizeCorrispondenceResult from './pages/TireSizeCorrispondenceResult';
import CCalculator from './pages/CCalculator';
import CCalculatorResult from './pages/CCalculatorResult';
import Error404 from './pages/Error404';

    let scroll_mem=new Array();
    let prev_pathname='';
    
export default function MainContainer() {
    const location = useLocation();
    const history = useHistory();
    let scroll_to=0;
    
    let check_animation = location.pathname;
    if (location.pathname.includes('/product-range'))
    {
        check_animation ='/product-range/';
    }
    if (location.pathname.includes('/technical-data') && !location.pathname.includes('/technical-data-'))
    {
        check_animation ='/technical-data/';
    }
    if (location.pathname.includes('/technical-informations') && !location.pathname.includes('/technical-informations-'))
    {
        check_animation ='/technical-informations/';
    }
    
    if (prev_pathname)
    {
        scroll_mem[prev_pathname]=window.pageYOffset;
    }
    if (scroll_mem[location.pathname])
    {
        scroll_to=scroll_mem[location.pathname];
    }
    prev_pathname=location.pathname;
    
    return (
  
        <AppProvider baseUrl="https://trelleborgtirebook.com/wp/" history={history} scroll_to={scroll_to}  >
            <Topbar location_pathname={location.pathname} />
            <TransitionGroup>
                <CSSTransition key={check_animation} classNames="slide" timeout={500}>
                    <Switch location={location}>
                        <Route  path="/"  component={Languages} exact />
                        <Route  path="/:language/" component={Home} exact/>
                        <Route  path="/:language/product-range/" component={ProductRange} exact/>
                        <Route  path="/:language/product-range/:first/" component={ProductRange} exact/>
                        <Route  path="/:language/product-range/:first/:second/" component={ProductRange} exact/>
                        <Route  path="/:language/product-search/" component={ProductSearch}/>
                        <Route  path="/:language/product/:slug/" component={Product}/>
                        <Route  path="/:language/size/:id/" component={Size}/>
                        <Route  path="/:language/technical-data/" component={TechnicalData}/>
                        <Route  path="/:language/technical-data-symbols/" component={TechnicalDataSymbols}/>
                        <Route  path="/:language/technical-informations/" component={TechnicalInformations} exact/>
                        <Route  path="/:language/technical-informations/:first/" component={TechnicalInformations} exact />
                        <Route  path="/:language/technical-informations-detail/:first/:second/" component={TechnicalInformationsPage}/>
                        <Route  path="/:language/tire-size-correspondence/" component={TireSizeCorrispondence}/>
                        <Route  path="/:language/tire-size-correspondence-result/:size" component={TireSizeCorrispondenceResult}/>
                        <Route  path="/:language/mechanical-lead-calculator/" component={MechanicalLeadCalculator}/>
                        <Route  path="/:language/contacts/" component={Contacts}/>
                        <Route  path="/:language/social/" component={Social}/>
                        <Route  path="/:language/apps/" component={Apps}/>
                        <Route  path="/:language/tire-pressure-calculator/" component={CCalculator}/>
                        <Route  path="/:language/tire-pressure-calculator-result/:size" component={CCalculatorResult}/>
                        <Route component={Error404}/>
                    </Switch>
                </CSSTransition>
            </TransitionGroup>
         </AppProvider >
    );
}

/*<Route  path="/"   exact><Redirect to="/en/" /></Route>*/