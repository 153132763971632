import React from 'react';
import { Link } from 'react-router-dom';
import AppContext from './../helpers/AppContext'
import Scroller from './../helpers/Scroller'
import SizeFilter from './../helpers/SizeFilter'
import SizeParams from './../helpers/SizeParams'

import { Container, Row, Col, Accordion, Card, Button, Tabs, Tab, InputGroup,FormControl, Form  } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import Breadcrumb from './../helpers/Breadcrumb';



export default class TireSizeCorrispondence extends React.Component 
{
    static contextType = AppContext;
    constructor(props) 
    {
        super(props);
        this.state = {filtro_completato: false};
        this.onCompleteFilter = this.onCompleteFilter.bind(this);
        this.onUncompleteFilter = this.onUncompleteFilter.bind(this);
    }
    componentDidMount()
    {
        const params={
            language:this.props.match.params.language,
            structure:1,
            size_list:1,
            level:20,
            
        };
        const { setData } = this.context;
        setData(params);
        
    }
    componentWillUnmount()
    {
        const { resetSize} = this.context;
        resetSize();
    }
    
    onCompleteFilter(size)
    {
        const { setLastTSCOpen } = this.context;
        setLastTSCOpen(size);
        this.setState({size:size, filtro_completato:true})
    }
    onUncompleteFilter()
    {
        
        this.setState({filtro_completato:false})
        
    }
    render() 
    {
        const { structure, loading, language, size_list,lastTSCOpen } = this.context;
       
       
        if (size_list.length !=0 )
        {
            let auto_key='';
            let auto_container_class=this.state.filtro_completato ? '': 'disabled';
            let value;
            let link='';
            if (this.state.size)
            {
                link='/'+language+'/tire-size-correspondence-result/'+this.state.size.ID_code
            }
            let lista_parametri=new Array();
            
            
            Object.keys(structure.tire_size_corrispondence.auto_lista).map((index, i) => 
            {     
                auto_key=structure.tire_size_corrispondence.auto_lista[Object.keys(structure.tire_size_corrispondence.auto_lista)[index]]
                if (this.state.filtro_completato)
                {
                    lista_parametri.push({titolo:auto_key.titolo,value:this.state.size[auto_key.variabile]});
                }else{
                    lista_parametri.push({titolo:auto_key.titolo,value:''});
                }                                
            })
           
            return (
                <div >
                    <div className='main tire-size-corrispondence'  id="tire-size-corrispondence">
                    <Breadcrumb current={structure.tire_size_corrispondence.titolo}></Breadcrumb>
                        <div className="main-sep"></div>
                        <Container >
                            <Row>
                                
                                <Col xs={12}>
                                    <div className="titolo">
                                        <div dangerouslySetInnerHTML={{__html: structure.tire_size_corrispondence.titolo}}></div>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className="symbol">
                                        <div className='mb-15'>
                                            <Link to={'/'+language+'/technical-data-symbols/'} >
                                                <div className='btn'>
                                                    {structure.technical_data.symbol_button}
                                                </div>  
                                            </Link>
                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        <Container >
                            <SizeFilter containerId='tire-size-corrispondence' lastTSCOpen={lastTSCOpen} onCompleted={this.onCompleteFilter} onUncompleted={this.onUncompleteFilter}  select_lista={structure.tire_size_corrispondence.select_lista} />
                        </Container>
                        
                        <SizeParams titolo={structure.tire_size_corrispondence.scheda_titolo} containerClass={auto_container_class} lista={lista_parametri} ></SizeParams>
                     
                                  
                       
                        <Container className={'btn-container '+auto_container_class } >
                            <Row>
                                <Col xs={12} >
                                <Link to={link}><div className='btn btn-blue' dangerouslySetInnerHTML={{__html: structure.tire_size_corrispondence.mostra_risultati}}></div></Link>
                                <div  className='h-f'></div>       
                                </Col>
                            </Row>
                        </Container>
                    </div>
                             
                    <Scroller id='tire-size-corrispondence' />
                </div>
            );
        }else{
            return(<div>
            <div className='main bacground-grey'>
                <div className="main-sep"></div>
                <Container>
                    <Row>
                        <Col>
                            <Skeleton height={30}/><br/><br/>
                            <Skeleton height={15} /><Skeleton height={30} /><br/>
                            <Skeleton height={15} /><Skeleton height={30} /><br/>
                            <Skeleton height={15} /><Skeleton height={30} /><br/>
                            <Skeleton height={15} /><Skeleton height={30} /><br/>
                            <Skeleton height={15} /><Skeleton height={15} /><br/>
                            <Skeleton height={15} /><Skeleton height={15} /><br/>
                            <Skeleton height={30}/>
                        </Col>
                    </Row>
                </Container>
                </div></div>);
        }
    }
    
}
 
